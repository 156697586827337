import React, { useEffect, useMemo, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import BuildsContext from './BuildsContext'
import AppsContext from '../../Sonars/AppsContext'
import api from '../../../shared/api'
import { useAuth } from '../../OAuth/Provider'

function BuildsProvider({ children, query }) {
  const { dataApps } = useContext(AppsContext)
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState([])
  const { getToken } = useAuth()

  const fetchBuilds = () => {
    setLoading(true)
    getToken()
      .then((accessToken) => {
        let applicationCode = query.applicationCode
        const thread = query.thread
        if (!applicationCode) {
          const applicationCodes = dataApps.map((app) => app.code)
          applicationCode = applicationCodes.join(',')
        }
        api
          .get(
            `/v1/settings/builds/list?applicationCode=${applicationCode}${
              thread ? `&thread=${thread}` : ``
            }`,
            {
              authorization: accessToken,
            }
          )
          .then((r) => {
            setData(r.builds)
            setLoading(false)
            setLoaded(true)
          })
          .catch((e) => {
            setError(e.message)
            setLoading(false)
          })
      })
      .catch((e) => {
        setError(e.message)
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchBuilds()
  }, [getToken, query.applicationCode, dataApps, query.thread])

  const contextValue = useMemo(
    () => ({
      loaded,
      loading,
      error,
      data,
      setData,
      refreshBuilds: fetchBuilds,
    }),
    [loaded, loading, error, data]
  )

  return (
    <BuildsContext.Provider value={contextValue}>
      {children}
    </BuildsContext.Provider>
  )
}

BuildsProvider.propTypes = {
  children: PropTypes.node,
  query: PropTypes.shape({
    thread: PropTypes.string,
    applicationCode: PropTypes.string,
  }),
}

export default BuildsProvider
