import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { PieChart, Pie, Cell, Tooltip } from 'recharts'
import LoadingAndAlertChart from '../../shared/ui/Components/LoadingAndAlertChart.jsx'
import UserDeviceContext from '../../app/Stats/UserDeviceContext.js'
import PropTypes from 'prop-types'

const colors = [
  '#ffcc00',
  '#aad400',
  '#c90b0b',
  '#007bff',
  '#ff6f61',
  '#6a0dad',
]

const CustomLegend = ({ data }) => (
  <Box display="flex" flexDirection="column" justifyContent="center">
    {data.map((item, index) => (
      <Box key={index} display="flex" alignItems="center" mb={1}>
        <Box
          sx={{
            width: 12,
            height: 12,
            backgroundColor: item.color,
            display: 'inline-block',
            marginRight: '8px',
          }}
        />
        <Typography variant="body2">{item.label}</Typography>
      </Box>
    ))}
  </Box>
)

CustomLegend.propTypes = {
  data: PropTypes.array,
}

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

function DiagramChart() {
  const { data, loading, error } = useContext(UserDeviceContext)

  const aggregatedData = data.map((item, index) => ({
    label: item.manufacturer || item.os || item.version || 'Unknown',
    count: item.count,
    color: colors[index % colors.length],
  }))

  return (
    <>
      <LoadingAndAlertChart error={error} loading={loading} diagram={true} />

      {!loading && aggregatedData.length > 0 && (
        <Box display="flex" flexDirection="row" alignItems="center">
          <PieChart width={250} height={250}>
            <Pie
              data={aggregatedData}
              dataKey="count"
              nameKey="label"
              outerRadius={100}
              labelLine={false}
              label={renderCustomizedLabel}
            >
              {aggregatedData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip formatter={(value) => `${value} устройств`} />
          </PieChart>
          <CustomLegend data={aggregatedData} />
        </Box>
      )}
    </>
  )
}

export default DiagramChart
