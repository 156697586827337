import React, { useEffect, useState, useMemo } from 'react'
import { useAuth } from '../../app/OAuth/Provider'
import { Box, Stack } from '@mui/material'
import SystemMenu from '../../shared/ui/SystemMenu'
import ContentPaper from '../../shared/ui/Components/ContentPaper'
import BuildsTable from '../../widgets/DataView/BuildsTable'
import AppsProvider from '../../app/Sonars/AppsProvider'
import BuildsProvider from '../../app/Settings/Builds/BuildsProvider'
import SelectBuilds from '../../widgets/Select/SelectBuilds'
import BuildCounterProvider from '../../app/Settings/Builds/BuildCounterProvider'
import {
  getStartDate,
  getEndDate,
} from '../../shared/ui/Components/ChartWrapper/datePeriodHelper'

function Builds() {
  const [selectedApp, setSelectedApp] = useState('sonar')
  const [selectedBranch, setSelectedBranch] = useState('test')
  const { isAuthenticated, getToken, login } = useAuth()
  useEffect(() => {
    getToken()
      .then((r) => {})
      .catch((r) => login())
  }, [])

  useEffect(() => {
    if (!isAuthenticated) login()
  }, [isAuthenticated])

  const handleAppChange = (event) => {
    setSelectedApp(event.target.value)
  }

  const handleBranchChange = (event) => {
    setSelectedBranch(event.target.value)
  }

  const query = useMemo(
    () => ({
      code: selectedApp,
      thread: selectedBranch,
      startDate: getStartDate('week'),
      endDate: getEndDate(),
    }),
    [selectedApp, selectedBranch]
  )

  return (
    <SystemMenu>
      <h1>Сборки</h1>
      <AppsProvider>
        <BuildsProvider
          query={{
            applicationCode: selectedApp,
            thread: selectedBranch,
          }}
        >
          <Stack direction="column">
            <SelectBuilds
              selectedApp={selectedApp}
              selectedBranch={selectedBranch}
              handleAppChange={handleAppChange}
              handleBranchChange={handleBranchChange}
            />
            <BuildCounterProvider query={query}>
              <ContentPaper>
                <BuildsTable
                  startDate={query.startDate}
                  endDate={query.endDate}
                  selectedApp={selectedApp}
                  selectedBranch={selectedBranch}
                />
              </ContentPaper>
            </BuildCounterProvider>
            <Box height={20} />
          </Stack>
        </BuildsProvider>
      </AppsProvider>
    </SystemMenu>
  )
}

export default Builds
