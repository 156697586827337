import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import IndicatorsContext from './UserDeviceContext'
import api from '../../shared/api'
import { useAuth } from '../OAuth/Provider'

function UsersDeviceProvider({ children, query }) {
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState([])
  const { getToken } = useAuth()

  useEffect(() => {
    setLoading(true)
    getToken()
      .then((accessToken) => {
        api
          .get(`/v1/stats/devices` + '?' + api.objectToQuery(query), {
            authorization: accessToken,
          })
          .then((r) => {
            setData(r)
            setLoading(false)
            setLoaded(true)
          })
          .catch((e) => {
            setError(e.message)
            setLoading(false)
          })
      })
      .catch((e) => {
        setError(e.message)
        setLoading(false)
      })
  }, [getToken, query])

  const contextValue = useMemo(
    () => ({
      loaded,
      loading,
      error,
      data,
    }),
    [loaded, loading, error, data]
  )

  return (
    <IndicatorsContext.Provider value={contextValue}>
      {children}
    </IndicatorsContext.Provider>
  )
}

UsersDeviceProvider.propTypes = {
  children: PropTypes.node,
  query: PropTypes.shape({
    startDate: PropTypes.number,
    endDate: PropTypes.number,
  }),
}

export default UsersDeviceProvider
