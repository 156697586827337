import React, { useState, useContext, useEffect, useRef } from 'react'
import BuildCounterContext from '../../app/Settings/Builds/BuildCounterContext'
import PropTypes from 'prop-types'
import { Box, CircularProgress, Typography } from '@mui/material'
import { AreaChart, Area } from 'recharts'
import { groupDataBySegmentWithRanges } from './dateUtils'

function AreaChartCounter({ startDate, endDate, id }) {
  const { data, loading } = useContext(BuildCounterContext)
  const [chartData, setChartData] = useState([])
  const segment = 'day'
  const segmentSize = 24 * 60 * 60 * 1000
  const ref = useRef(null)

  useEffect(() => {
    if (data && data[id]) {
      const transformedData = data[id].map((item) => ({
        timestamp: item.timestamp,
        count: item.count,
      }))
      setChartData(transformedData)
    } else {
      setChartData([])
    }
  }, [data, id])

  const gdata = groupDataBySegmentWithRanges(
    chartData,
    segment,
    startDate,
    endDate,
    segmentSize
  )

  let totalCount = gdata.reduce((total, item) => total + item.count, 0)
  if (isNaN(totalCount)) {
    totalCount = 0
  }
  return (
    <Box ref={ref} sx={{ position: 'relative' }}>
      {loading && <CircularProgress size={24} />}
      {!loading && (
        <Box display={'flex'}>
          {gdata.length === 0 || totalCount === 0 ? (
            <Typography color="#999">&mdash;</Typography>
          ) : (
            <>
              <AreaChart
                width={130}
                height={45}
                data={gdata}
                margin={{ top: 0, right: 0, left: 5, bottom: 0 }}
              >
                <Area
                  marginRight="20px"
                  connectNulls
                  type="monotone"
                  dataKey="count"
                  stroke="#8884d8"
                  fill="#eeeeee"
                />
              </AreaChart>
              <Box ml={2} display="flex" alignItems="center">
                <Typography sx={{ opacity: 0.8 }}>{totalCount}</Typography>
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  )
}

AreaChartCounter.propTypes = {
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  id: PropTypes.string,
}

export default AreaChartCounter
