import React, { useContext, useRef, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import {
  BarChart,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Line,
} from 'recharts'
import StatsContext from '../../app/Stats/StatsContext.js'
import { useContainerDimensions } from '../../shared/useContainerDimensions.jsx'
import PropTypes from 'prop-types'
import CustomTooltip from '../../shared/ui/Components/CustomTooltip.jsx'
import LoadingAndAlertChart from '../../shared/ui/Components/LoadingAndAlertChart.jsx'
import { groupDataBySegmentWithRanges } from './dateUtils.jsx'

function LinearChart({ segment, chartType, startDate, endDate }) {
  const { data, loading, error } = useContext(StatsContext)

  const [segmentSize, setSegmentSize] = useState(24 * 60 * 60 * 1000)

  useEffect(() => {
    const sizes = {
      day: 24 * 60 * 60 * 1000,
      week: 7 * 24 * 60 * 60 * 1000,
      month: 30 * 24 * 60 * 60 * 1000,
    }
    setSegmentSize(sizes[segment] || sizes.day)
  }, [segment])

  const ref = useRef(null)
  const { width } = useContainerDimensions(ref)

  const gdata = groupDataBySegmentWithRanges(
    data,
    segment,
    startDate,
    endDate,
    segmentSize
  )

  return (
    <Box ref={ref} sx={{ position: 'relative', minHeight: '300px' }}>
      <LoadingAndAlertChart error={error} loading={loading} />
      {!loading &&
        (chartType === 'bar' ? (
          <BarChart
            width={width}
            height={300}
            data={gdata}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="timestamp" />
            <YAxis />
            <Tooltip content={<CustomTooltip type="linear" />} />
            <Bar
              dataKey="count"
              fill="#56c"
              background={{ fill: 'rgba(0,0,0,0.05)' }}
            />
          </BarChart>
        ) : (
          <LineChart
            width={width}
            height={300}
            data={gdata}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="timestamp" />
            <YAxis />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Tooltip content={<CustomTooltip type="linear" />} />
            <Line type="monotone" dataKey="count" stroke="#8884d8" />
          </LineChart>
        ))}
    </Box>
  )
}

LinearChart.propTypes = {
  segment: PropTypes.oneOf(['day', 'week', 'month']).isRequired,
  chartType: PropTypes.oneOf(['bar', 'line']).isRequired,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
}

export default LinearChart
