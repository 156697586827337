import React, { useState, useEffect, useCallback } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material'
import PropTypes from 'prop-types'

const EditDialogBuilds = ({ show, onHide, onSave, data, setData }) => {
  const [dialogData, setDialogData] = useState(data)

  useEffect(() => {
    setDialogData(data)
  }, [data])

  const handleChangesChange = useCallback((event) => {
    const { name, value } = event.target
    setDialogData((prev) => ({ ...prev, [name]: value }))
  }, [])

  const handlePublicChange = useCallback((event) => {
    const checked = event.target.checked
    setDialogData((prev) => ({ ...prev, publish: checked }))
  }, [])

  const handleSaveClick = () => {
    setData(dialogData)
    onSave(dialogData)
  }

  return (
    <Dialog open={show} onClose={onHide} maxWidth="xl">
      <DialogTitle>Изменения</DialogTitle>
      <DialogContent sx={{ width: '850px' }}>
        <TextField
          margin="dense"
          id="changes-set"
          label="Изменения"
          name="changes"
          type="text"
          multiline
          fullWidth
          rows={15}
          value={dialogData.changes || ''}
          onChange={handleChangesChange}
        />
        <Tooltip
          title={
            dialogData.publish
              ? 'Нажмите, чтобы убрать сборку с публикации'
              : 'Нажмите, чтобы опубликовать сборку'
          }
          arrow
          placement="top"
        >
          <FormControlLabel
            control={
              <Switch
                checked={dialogData.publish || false}
                onChange={handlePublicChange}
                color={dialogData.publish ? 'success' : 'error'}
              />
            }
            label={dialogData.publish ? 'Опубликована' : 'Не опубликована'}
            sx={{ mt: 2 }}
          />
        </Tooltip>
      </DialogContent>
      <DialogActions>
        <Button onClick={onHide}>Отмена</Button>
        <Button
          onClick={handleSaveClick}
          color="primary"
          variant="contained"
          disableElevation
        >
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  )
}

EditDialogBuilds.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  data: PropTypes.shape({
    changes: PropTypes.string,
    publish: PropTypes.bool,
  }).isRequired,
  setData: PropTypes.func,
}

export default EditDialogBuilds
