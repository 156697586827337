const features = {
  STATS_INSTALLATIONS: true,
  STATS_DATA: true,
  UPDATE_APPS: true,
  UPDATE_BUILDS: true,
  SONARS_JOURNALS: true,
  SETTINGS: true,
  USERS: true,
  BUILD_PUBLISH: true,
}

export default features
